#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.logo img {
    height: 25px;
    /* margin: 16px; */
    /* background-color: red; */
}

.layout__children {
    background: #fff;
    /* margin-top: 1.2em; */
    /* border: 1px solid red; */
    height: calc(100vh - 64px);
}

.layout__header {
}
.ant-layout-header div {
    /* height: 50px; */
}
