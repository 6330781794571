:root {
    --backdrop: #eeeeee;
    --background: #ffffff;

    --blueTitle: #1f4d84;
    --lightRed: #ff5444;
    --lightGreen: #e3f99e;
    --violet: #ab7bbb;
}

body {
    margin: 0;
    padding: 0px;
}

p {
    margin: 0;
}
