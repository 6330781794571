.container__map__modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.container__details__table {
    width: 30%;
    height: 100%;
    margin-right: 1em;
    margin-left: 1em;
}
