.director__container {
    padding: 2em;
}

.director__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 15px 0px;
}
