.Container__chart_line {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    align-items: center;
    -webkit-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    -moz-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
}

.Chart__line_title {
    color: #8fabe1 !important;
    margin-top: 1em;
    font-size: 16px;
}

.Chart__line {
    width: 100%;
    padding: 1em;
}
