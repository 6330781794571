.login__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.login__logo {
    width: 300px;
    margin-bottom: 2em;
}

.login__logo img {
    width: 100%;
}

.login__form {
    width: 300px;
}

.login__loader {
    display: flex;
    justify-content: center;
    align-items: center;
}
