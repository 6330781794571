.container__createLocation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.box__modal__createLocation {
    width: 80%;
    height: 500px;
}

@media only screen and (max-width: 640px) {
    .container__createLocation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .box__modal__createLocation {
        width: 100%;
        height: 400px;
    }
}
