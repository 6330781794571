.hero {
    width: 100%;
    height: auto;
    display: flex;
    border-bottom: 1px solid #ecf0f2;
    align-items: center;
}

.hero__icon {
    width: 1.5rem;
    height: 1.5rem;
    /* margin-left: 0;*/
    margin-right: 1.5em;
}

.hero__breadcrumb {
    font-size: 17px;
    font-weight: 500;
}
