.users__icon {
    height: 30px;
    width: 35px;
    margin-left: 50px;
}

.actions__icon {
    display: flex;
    justify-content: space-around;
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}
