.Dashboard__container {
    margin: 2em 2em 2em 2em;
}

.Dashboard__container_kpi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 130px;

    /* min-width: 700px; */
    max-width: 1000px;
}

.Dashboard__kpi {
    border-radius: 8px;
    width: 12em;
    -webkit-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    -moz-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
}

.Dashboard__container_charts {
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Dashboard__container_list {
    display: flex;
    width: 49%;
    justify-content: space-between;
}

.Dashboard__list_best,
.Dashboard__list_worst {
    width: 48%;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    -moz-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
}

.Dashboard__container_plot {
    width: 48%;
}

@media only screen and (max-width: 1080px) {
    .Dashboard__container_kpi {
        width: auto;
    }

    .Dashboard__kpi {
        display: block;
        white-space: nowrap;
    }

    .Dashboard__container_list {
        width: 52%;
    }

    .Dashboard__container_plot {
        width: 46%;
    }
}

@media only screen and (max-width: 910px) {
    .Dashboard__container_kpi {
        overflow: auto;
    }
    .Dashboard__kpi {
        padding-right: 1em;
        margin: 0;
    }
}

@media only screen and (max-width: 800px) {
    .Dashboard__container_charts {
        flex-direction: column;
    }

    .Dashboard__container_list {
        width: 100%;
        margin-bottom: 1.5em;
    }

    .Dashboard__container_plot {
        width: 100%;
    }
}

@media only screen and (max-width: 755px) {
    .Dashboard__container_kpi {
        width: auto;
        /* outline: red solid; */
    }
}

@media only screen and (max-width: 430px) {
    .Dashboard__container_list {
        flex-direction: column;
    }

    .Dashboard__container_plot {
        width: 100%;
    }

    .Dashboard__list_best {
        width: 100%;
    }

    .Dashboard__list_worst {
        width: 100%;
        margin-top: 1.5em;
    }
}
